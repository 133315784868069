import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import LocalizedLink from "../components/localized-link"
import PropTypes from "prop-types"
import LeftRightSections from "../components/left-right-sections"
import { Swiper, SwiperSlide } from "swiper/react"
import PreviewCompatibleImage from "../components/preview-compatible-image"
import { HTMLContent } from "../components/content"
import MarkdownIntroSection from "../components/markdown-intro"
import TestimonialsSlider from "../components/testimonials-slider"
import { FormattedDate, FormattedMessage } from "react-intl"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"

const HeroSection = ({ heros, preview }) => {
  return !heros ? null : (
    <section className="container mx-auto bg-wpt-grey">
      <Swiper
        speed={750}
        pagination={{ clickable: true }}
        autoplay={!preview}
        autoHeight={!preview}
      >
        {heros.map((hero, index) =>
          !hero ? null : (
            <SwiperSlide key={hero.title + index}>
              <div className="flex flex-col-reverse justify-center lg:flex-row">
                <div className="p-6 pb-12 flex flex-col justify-center items-baseline space-y-8 lg:w-1/3 lg:px-12 lg:p-6">
                  <h3 className="text-black">{hero.title || ""}</h3>
                  <p className="text-base font-light">
                    {hero.description || ""}
                  </p>
                  {!!hero.buttonText && !!hero.buttonLink && (
                    <LocalizedLink
                      to={hero.buttonLink}
                      className="btn-primary text-base"
                    >
                      {hero.buttonText}
                    </LocalizedLink>
                  )}
                </div>
                {!!hero.image && (
                  <div className="lg:w-2/3 flex">
                    <PreviewCompatibleImage
                      className="w-full h-full object-cover"
                      image={hero.image}
                      alt={hero.imageAlt}
                    />
                  </div>
                )}
              </div>
            </SwiperSlide>
          )
        )}
      </Swiper>
    </section>
  )
}

HeroSection.propTypes = {
  heros: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      buttonLink: PropTypes.string,
      buttonText: PropTypes.string,
      imageAlt: PropTypes.string,
      image: PropTypes.object,
    })
  ),
  preview: PropTypes.bool.isRequired,
}

const IntroSection = ({ title, description }) => {
  return (
    <section className="container mx-auto mt-8 px-5">
      <h1 className="text-center">{title}</h1>
      <p className="mt-3 text-center leading-relaxed text-base mx-auto lg:w-2/3">
        {description}
      </p>
    </section>
  )
}

IntroSection.defaultProps = {
  title: "",
  description: "",
}

IntroSection.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

const WhatWeDoSection = ({ whatWeDo }) => {
  return (
    <section className="flex flex-col space-y-12 container mx-auto px-5 mt-12">
      {whatWeDo.map((whatWeDo, index) => (
        <LeftRightSections
          key={whatWeDo.title}
          buttonLink={whatWeDo.buttonLink}
          buttonText={whatWeDo.buttonText}
          description={whatWeDo.description}
          image={whatWeDo.image}
          title={whatWeDo.title}
          imageAlt={whatWeDo.imageAlt}
          odd={index % 2 !== 0}
        />
      ))}
    </section>
  )
}
WhatWeDoSection.propTypes = {
  whatWeDo: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      buttonLink: PropTypes.string,
      buttonText: PropTypes.string,
      imageAlt: PropTypes.string,
      image: PropTypes.object,
    })
  ),
}

const TestimonialSection = ({ testimonials, preview }) => {
  return (
    <section className="mt-12 bg-wpt-primary">
      <TestimonialsSlider testimonials={testimonials} autoHeight={!preview} />
    </section>
  )
}

TestimonialSection.propTypes = {
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      author: PropTypes.string,
      authorInfo: PropTypes.string,
    })
  ),
  preview: PropTypes.bool.isRequired,
}

const SmallNewsListSection = ({ edges }) => {
  return !edges ? null : (
    <section className="container mx-auto mt-8 px-5 grid grid-cols-1 lg:grid-cols-2 gap-x-48 gap-y-12">
      {edges.map(
        (
          {
            node: {
              frontmatter,
              excerpt,
              fields: { slug },
            },
          },
          index
        ) => (
          <div key={frontmatter.title + index}>
            <p className="font-light">
              {!!frontmatter.date && (
                <>
                  <FormattedDate value={frontmatter.date} dateStyle="long" />
                  <span className="mx-2">|</span>
                </>
              )}
              {frontmatter.author.name || ""}
            </p>
            <hr className="border border-black mt-4 mb-2" />
            <h1 className="mb-4 mt-4 transition hover:opacity-75 text-2xl lg:text-4xl">
              <Link to={slug || "/"}>{frontmatter.title || ""}</Link>
            </h1>
            <p>{excerpt || ""}</p>
            <Link
              to={slug || "/"}
              className="inline-flex items-center btn-secondary pl-0"
            >
              <FormattedMessage id="readMore" defaultMessage="Read more" />
              <FontAwesomeIcon className="ml-1" icon={faChevronRight} />
            </Link>
          </div>
        )
      )}
    </section>
  )
}

SmallNewsListSection.propTypes = {
  edges: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        frontmatter: PropTypes.shape({
          title: PropTypes.string,
          date: PropTypes.string,
          author: PropTypes.shape({ name: PropTypes.string }),
        }),
        excerpt: PropTypes.string,
        fields: PropTypes.shape({
          slug: PropTypes.string,
        }),
      }),
    })
  ),
}

export const IndexPageTemplate = ({
  hero,
  intro,
  testimonials,
  whatWeDo,
  newsIntroMD,
  edges,
  preview,
}) => {
  return (
    <>
      <HeroSection heros={hero} preview={!!preview} />
      <IntroSection {...intro} />
      <WhatWeDoSection whatWeDo={whatWeDo} />
      <TestimonialSection testimonials={testimonials} preview={!!preview} />
      <MarkdownIntroSection
        html={newsIntroMD}
        contentComponent={!preview && HTMLContent}
      />
      <SmallNewsListSection edges={edges} />
    </>
  )
}

const IndexPage = props => {
  return (
    <Layout>
      <Seo title={props.title} description={props.description} />
      <IndexPageTemplate {...props} />
    </Layout>
  )
}

export default IndexPage
